@font-face {
  font-family: "KlarheitKurrent";
  src: url("./assets/fonts/klarheitkurrent/KLARHEITKURRENT-REGULAR.TTF");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "KlarheitKurrent";
  src: url("./assets/fonts/klarheitkurrent/KLARHEITKURRENT-BOLD.TTF");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

body {
  background: #fff top/100% !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.mylist {
  margin: 2em 0;
}

.mylist li {
  margin: 1em 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.reports tbody tr td {
  cursor: pointer;
}